import React from "react"
import { Link, navigate } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import HubspotForm from "react-hubspot-form"
import { Tabbordion, TabPanel, TabContent } from "react-tabbordion"
import useLiveControl from "../controlled/useLiveControl"
// import LiveControlSpinner from "../components/liveControlSpinner"
import Seo from "../components/seo"

// accordian
const blockElements = {
  animator: "accordion-animator--unchecked",
  content: "accordion-content",
  panel: "accordion-panel",
  label: "accordion-title",
}

const customLink = (url, e) => {
  navigate(url)
}

const HomeImprovementFinancing = () => {
  const { isStaging } = useLiveControl()

  // if (runningChecks || !isStaging) return <LiveControlSpinner />

  return (
    <Layout>
      <Seo
        title={"Home Improvement Financing"}
        description={
          "Regions EnerBank helps homeowners get the home improvement financing they need to achieve the home of their dreams. Contractors benefit by offering EnerBank financing to their customers."
        }
      />
      <Helmet>
        <body id="rebrand" className="page-home-improvement-financing" />
      </Helmet>
        <section 
        className="banner-area"
        id="home-improvement-financing-banner-section">
          <div className="container banner-container"
              style={{
                        backgroundImage:
                          "url(" +
                          "https://gbdevpress.enerbank.com/wp-content/themes/enerbank/img/hero-img.jpg" +
                          ")",
                      }}
              >
            <div className="relative banner-area-text-container">
                    <div className="container text-center">
                      <div className="identifier-container row-fluid">
                        <div className="element span12">
                            <div className="rhif-identifier span5">
                                <h4>HOME IMPROVEMENT FINANCING</h4>
                            </div>
                            <div className="right-angle span2"></div>
                        </div>
                      </div>
                      <h1 className="text-white text-left desktop" style={{ padding: "80 0" }}>
                       Home Improvement Financing<br/> from Regions
                      </h1>
                    </div>
                  </div>
            </div>
            <div className="container text-center mobile">
            <h1 className="text-white text-left" style={{ padding: "80 0" }}>
             Home Improvement Financing from Regions
            </h1>
          </div>
          </section>
      <section
        className="paragraph-text-block pt-0"
        id="contractor-financing-paragraph-text-block"
      >
        <div className="container">
          <div className="justify-content-center">
            <div className="pb-0 pt-5">
              <div>
                <p>
                  When it&#39;s time to start a home improvement project, people
                  often find the cost of the project they really want often
                  exceeds their cash on hand. Research shows that over 55% of
                  home improvement projects are financed in some way<sup>*</sup>.
                </p>
                <p>
                  Some homeowners choose to use a credit card, despite high
                  interest rates. Others spend time and energy comparing fees
                  and rates from banks and credit unions. Some homeowners,
                  however, benefit from working with a contractor that offers
                  in-house financing options. That kind of convenience is hard
                  to beat.
                </p>
                <p className="mb-0">
                  <small>
                    <sup>*</sup>HIRI TFG Monthly report, released October 2023
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-homeowners-choose-regions bg-light-gray">
        <div>
          <div className="container">
            <h2 className="text-center">
              Why homeowners choose<br/> Regions Home Improvement Financing
            </h2>
          </div>
        </div>
        <div className="container section-padding py-5 my-3">
          <div className="col-12">
            <p className="">
              Regions Home Improvement Financing is a specialized home improvement lender – that means
              we&#39;re experienced when it comes to providing financing for
              home improvement projects like yours. Since it&#39;s our
              specialty, we&#39;ve developed processes and payment options that
              are tailored to home improvement needs. In the over two decades
              that we&#39;ve been in business, we&#39;ve approved over one million
              home improvement loans.
            </p>
              <p>
                Instead of saving up or completing the job in stages, you can get
                the entire project completed now, without compromises. With a Regions 
                Home Improvement Financing loan, your home is not used as collateral, there&#39;s no
                application fee, no closing or appraisal costs, and no prepayment
                penalties. Our paperless application process is quick & easy, and
                you can often receive a credit decision within seconds. Approvals
                are valid for 120-180 days, giving you plenty of time to complete
                your home improvement project.
              </p>
            </div>
          </div>
          <div className="container-lg">
          <div className="icon-cards row justify-content-center">
            <div className="col-md-2">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/3-quick-easy-paperless-application-methods.png" className="icon" alt="Quick and easy application process" width="80" /> 
                </div>
                <div className="card-body">
                  <h3>Quick and Easy application process</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/us-based-lending-customer-service.png" className="icon" alt="U.S.Based Lending & Customer Service" /> 
                </div>
                <div className="card-body">
                  <h3>U.S. Based Lending & Customer Service</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/fdic-insured.png" className="icon" alt="FDIC Insured" /> 
                </div>
                <div className="card-body">
                  <h3>FDIC<br/> Insured</h3>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card justify-content-inherit">
                <div className="card-image">
                  <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/high-approval-rates.png" className="icon" alt="High approlval rates" />
                </div>
                <div className="card-body">
                  <h3>High Approval Rates</h3>
                </div>
              </div>
              </div>
              <div className="col-md-2">
                <div className="card justify-content-inherit">
                  <div className="card-image payment-options">
                    <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/07/several-payment-options.png" className="icon" alt="several payment options" />
                  </div>
                  <div className="card-body">
                    <h3>Several Payment Options</h3>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
      <section className="rds-marquee marquee-teal">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5 order-2 order-md-1">
              <h2 className="text-white mb-5">Refer a contractor</h2>
              <p className="text-white">Refer a contractor to Regions&apos; Express Loan Program<br/> and receive a $300 Amazon.com Gift Card!</p>
              <div className="row justify-content-center align-items-top text-center pt-5">
                <div className="col-md-6 mb-4">
                  <button type="button" className="btn btn-info btn-gray" onClick={() =>
                        customLink("homeimprovement.rhif.regions.com/homeowner-referral")}>I'm a Homeowner</button>
                </div>
                <div className="col-md-6 text-center">
                  <button type="button" className="btn btn-info btn-gray" onClick={() =>
                        customLink("https://homeimprovement.rhif.regions.com/contractor-referral")}>I'm a Contractor</button>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2 text-center">
              <img
                  src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/amazon-300-gift-card-2.png"
                  className="mb-3"
                  alt="$300 Amazon gift card"
                />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light-gray section-padding why-contractors-enerbank py-5 d-none">
        <div className="container">
          <div className="row pb-5">
            <div className="col-md-6">
              <h2 className="pb-5 pt-2">
                Why so many contractors choose Regions
              </h2>
              <p className="mb-4">
                No matter how good a contractor is at their job, they won&apos;t
                stay in business if they can&apos;t attract customers. It&apos;s
                vital for contractors to increase their leads and close rate so
                they can show off their ability. When contractors offer a choice 
                of home improvement financing from Regions, they can see an
                increase in their leads as well bigger project sizes.
              </p>
            </div>
            <div className="col-md-6 text-center">
              < img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/08/nick-faletta-rebath.jpg" className="image" />
              {/* <iframe
                className="pt-2"
                src="https://player.vimeo.com/video/825916381?h=a20d926863"
                width="540"
                height="315"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
                title="EnerBank USA - Contractor Financing"
              ></iframe> */}
            </div>
          </div>
          <div className="icon-cards row">
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image">
                    <img
                      src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/07/flexibility.png"
                      alt="business expansion"
                    />
                  </div>
                <div className="card-body">
                  <h3>Increase Business Expansion</h3>
                  <p>
                    Research shows that over 55% of home improvement projects
                    are financed in some way<sup>*</sup>. By advertising
                    financing to your customers, you could see a significant
                    increase in leads.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image">
                    <img
                      src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/07/bar-graph-trending-up.png"
                      alt="stay-ahead"
                    />
                </div>
                <div className="card-body">
                  <h3>Stay Ahead of Your Competitors</h3>
                  <p>
                    With so many customers using financing, you may be missing
                    out on opportunities. Those lost customers end up with a
                    competitor, leaving you to settle for the leftovers. By
                    offering financing options, you can retain those customers
                    and stay ahead of the competition.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image">
                    <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/07/several-payment-options.png" class="icon" alt="multiple payment options" />
                </div>
                <div className="card-body">
                  <h3>Provide Customers with Options</h3>
                  <p>
                    Financing options let you offer various products at
                    different price points that can fit almost any budget — a
                    "good, better, best" approach to your sales process. When
                    customers see they have greater spending power and more
                    flexibility in their choices, they can be more confident in
                    their decisions and often spend more.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card justify-content-inherit">
                <div className="card-image">
                    <img
                      src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/05/Boost-close-rate_1.png"
                      alt="boost close rate"
                    />
                </div>
                <div className="card-body">
                  <h3>Boost Your<br/> Close Rate</h3>
                  <p>
                    Offering the right payment options can almost double your
                    close rate. We've found that companies with a 25% baseline
                    can increase their close rate to 44% when they offer a
                    choice of payment options.
                    <sup>†</sup>.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 py-5">
              <p className="text-center">
                <small>
                  <sup>*</sup>HIRI TFG Monthly report, released October 2023
                </small>
              </p>
              <p className="text-center">
                <small>
                  <sup>†</sup>Based on the Brickyard Study commissioned by Regions, 2018
                </small>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p className="text-center">
                <button
                  className="btn btn-info btn-green"
                  onClick={() =>
                    customLink("/sign-up/")}
                >
                  Start Offering Financing Now
                </button>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default HomeImprovementFinancing
